<template>
	<div class="landata">
		

		
		 <div class="barbox">
			<div class="bartitlebox">
				 <div class="kong"></div>
				<div class="title">
					<div class="titletop">{{ title }}</div>
					<!-- <div class="titlebottom">{{ remarks }}</div> -->
				</div>
				<!-- <div class="more" style="z-index:10" @click="toBigdata">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/icon/more.png" alt="">
				</div> -->
			</div>
			<div class="bar">
				<website-bar :data="bardata" :showField="['acreage','buildacreage', 'totalprice']"></website-bar>
			</div>
		</div> 

	</div>
</template>

<script>

import WebsiteBar from '@/components/echarts/WebsiteBar.vue'
export default {
	components: { WebsiteBar},
	name: 'PcWebsiteIndexlanddata',
	props:{
		title:{
			type:String,
			default:''
		},
		bardata: {
			type:Array,
			default:[]
		}
	},

	data() {
		return {
			
		}
	},
	


	mounted() {

	},

	methods: {


	


		toBigdata() {

			window.location.href = process.env.VUE_APP_LOGIN_INDEX_DOMAIN

		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/landdata/' + id
			// this.$router.push(tourl)

			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},




		tocity(item) {
			const dcityid = item.city_id
			landdata({ dcity_id: dcityid }).then(res => {
				this.citylist = this.citylist.map(item => {
					if (item.city_id === dcityid) {
						item.checked = true
					} else {
						item.checked = false
					}
					return item
				})
				this.gdp = res.data.gdp
				this.income = res.data.income
			})

		},


		
	
		//初始化列表
		initList() {
			const params = {
				site_id: this.site.id
			}
			if (this.currentcityid) {
				params.city_id = this.currentcityid
			}
			landdatalist(params).then(res => {



				const currentcity = res.data.citylist.filter(item => {
					return item.checked === true
				})

				if (this.site.path === 'cn') {
					this.citylist = res.data.citylist
					this.currentcityid = currentcity[0].city_id
				} else {
					this.citylist = []
				}


		
				this.title = res.data.title
				this.remarks = res.data.remarks
				this.bardata = res.data.bardata

			})
		}

	},
};
</script>

<style lang="scss" scoped>
.barbox {
	padding-top: 11px;
	margin-top: 4px;
	width: 100%;
	height: 229px;
	background: #FFFFFF;

	.bar {
		position: relative;
		top: -30px;
	}

	.bartitlebox {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.kong {

		width: 62px;
		height: 24px;
		background: #FFFFFF;

	}

	.title {
		flex: 1;

		.titletop {
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;

		}

		.titlebottom {
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;

		}
	}

	.more {
		width: 62px;
		height: 24px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #5B6980;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-right: 20px;
	}

}


.landata {
	height: 320px;
	width: 100%;
	// background:#fff;
}

</style>