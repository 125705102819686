<template>
  <div class="indexcontainer">
    <div class="iheader">
      <div class="lefttitle">
        <div class="ih-title titleselect" @click="topage('index')">土地数据</div>
        <div class="ih-title " @click="topage('dataindex')">经济数据</div>

      </div>
    </div>
    <div class="icontent">
      <div class="ileft">
        <div class="onebox">
          <blue-title name="正在公告" url="/data/notice?mode=table"></blue-title>

          <el-table :data="listdata.zzgg" stripe class="tableBox" style="width: 100%">
            <el-table-column prop="area" label="区县">
            </el-table-column>
            <el-table-column prop="locationnum" :show-overflow-tooltip='true' label="宗地编号">
            </el-table-column>
            <el-table-column prop="usecatename" label="用途">
            </el-table-column>
            <el-table-column prop="acreage" :show-overflow-tooltip='true' label="面积(m²)">

            </el-table-column>

            <el-table-column prop="bond" label="保证金(万元)">
            </el-table-column>
            <el-table-column prop="totalsp" :show-overflow-tooltip='true' label="起始总价(万元)">
            </el-table-column>
            <el-table-column prop="biddingst" :show-overflow-tooltip='true' label="限时竞价时间">
            </el-table-column>
          </el-table>

        </div>
        <div class="twobox">
          <blue-title name="最新成交" url="/data/deal?mode=table"></blue-title>

          <el-table :data="listdata.cj" stripe class="tableBox" style="width: 100%">

            <el-table-column prop="area" :show-overflow-tooltip='true' label="区县">
            </el-table-column>
            <el-table-column prop="locationnum" :show-overflow-tooltip='true' label="宗地编号">
            </el-table-column>
            <el-table-column prop="usecatename" :show-overflow-tooltip='true' label="用途">
            </el-table-column>
            <el-table-column prop="acreage" :show-overflow-tooltip='true' label="面积(m²)">
            </el-table-column>
            <el-table-column prop="tradeprice" :show-overflow-tooltip='true' label="成交总价(万元)">
            </el-table-column>
            <el-table-column prop="company" :show-overflow-tooltip='true' label="竞得单位">
            </el-table-column>
            <el-table-column prop="tradedate" :show-overflow-tooltip='true' label="成交时间">
            </el-table-column>
          </el-table>
        </div>
        <div class="threebox">
          <blue-title name="成交分析" url="/data/deal?mode=anylise"></blue-title>

          <landdata :title="listdata.beat_data_title" :bardata="listdata.beat_data"></landdata>
        </div>
      </div>

      <div class="iright">
        <div class="calendarbox">

          <blue-title name="土拍日程" moretext=""></blue-title>

          <calendar></calendar>
        </div>
        <div class="paihang">
          <!-- <div class="toptitle"> -->
          <blue-title :name="listdata.money_data_title" moretext=""></blue-title>
          <!-- </div> -->
          <div class="btnarea">
            <div :class="{ 'btn': true, 'btnchecked': btnchecked === 'money' }" @click="changeBtn('money')">拿地金额</div>
            <div :class="{ 'btn': true, 'btnchecked': btnchecked === 'area' }" @click="changeBtn('area')">拿地面积</div>
          </div>
          <div class="moneyara">
            <div class="phheader">
              <div class="hp hp1">排名</div>
              <div class="hp hp2">企业</div>
              <div class="hp hp3">{{ runkname }}</div>
            </div>
            <div class="linebox">
              <div class="line" v-for="item in 	list" :key="item.rank">
                <div class="showrank">
                  <div class="rank">{{ item.rank }}</div>
                </div>
                <div class="showname">
                  {{ item.name }}
                </div>
                <div class="showtext">{{ item.totalprice }}</div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { dataindexlist } from "@/api/index.js";
import BlueTitle from '../../components/BlueTitle.vue';

import Landdata from '@/components/data/Landdata.vue'
import Calendar from '../../components/Calendar.vue';
export default {
  components: {
    BlueTitle,
    Calendar,
    Landdata
  },

  data() {
    return {
      listdata: {
        zzgg: [],
      },
      list: [],
      rankname: '',
      btnchecked: 'money'

    }
  },

  computed: {
    shi: {
      get() {
        return this.$store.state.shi
      },
      set(v) {
        this.$store.dispatch('changeShi', v);
      }
    }
  },

  watch: {
    shi: {
      handler(v) {
        this.getData()
      }
    }
  },

  mounted() {
    this.getIndexlist();
  },

  methods: {


    changeBtn(string) {
      this.btnchecked = string
      if (string === 'money') {
        this.list = this.listdata.money_data
        this.runkname = '金额( )'
      } else {
        let arealist = JSON.parse(JSON.stringify(this.listdata.area_data))
        this.runkname = '面积(万方)'
        arealist = arealist.map(item => {
          item.totalprice = item.totalacreage
          return item
        })

        this.list = arealist
      }

    },



    tourl(url) {
      if (url !== '') {
        this.$router.push(url)
      }
    },

    topage(item) {
      this.$router.push(item)
    },

    getData() {
      this.getIndexlist()
    },

    getIndexlist() {
      const params = {
        dcity_id: this.shi,
        ym: null
      };
      var that = this;
      if (this.shi && this.shi != null) {
        dataindexlist(params).then((res) => {

          that.listdata = res.data;
          that.changeBtn('money')
        });
      }

    },
    toUrl(url) {
      this.$router.push(url)
    },

  },

};
</script>

<style lang="scss" scoped>
/deep/.tableBox {
  padding: 0 10px;

  th {
    padding: 0 !important;
    height: 10px;
    line-height: 10px;
    text-align: center;
    color: #000;
  }

  td {
    padding: 0 !important;
    height: 26px;
    line-height: 26px;
    text-align: center;
    color: #000;
  }
}


.icontent {
  box-sizing: border-box;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 96%;
  margin-left: 2%;
  flex: 1;
  height: calc(100vh - 160px);

  .ileft {
    box-sizing: border-box;
    width: 70%;
    height: 94%;
    padding: 0 5px;
    // background: red;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .onebox {
      width: 100%;
      min-height: 195px;
      background: #FFFFFF;
      border-radius: 10px;
    }

    .twobox {
      margin-top: 10px;
      width: 100%;
      min-height: 195px;
      background: #FFFFFF;
      border-radius: 10px;
    }

    .threebox {
      margin-top: 10px;
      width: 100%;
      height: 400px;
      background: #FFFFFF;
      border-radius: 10px;
      overflow: hidden;
    }
  }


  .iright {
    box-sizing: border-box;
    width: 30%;
    padding: 0 5px;
    height: 94%;
    // background: green;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .calendarbox {
      width: 100%;
      height: 400px;
      background: #FFFFFF;
      border-radius: 10px;

    }


    .paihang {
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      height: 400px;
      background: #FFFFFF;
      overflow: hidden;

      .toptitle {
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bluetitle {
          display: flex;

          .orange {
            margin-left: 20px;
            margin-right: 10px;
            width: 4px;
            height: 28px;
            background: rgba(231, 120, 23, 0.4);
          }


          .title {
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #E77817;
            line-height: 28px;
          }

        }
      }

      .btnarea {
        height: 28px;
        width: calc(100% - 40px);
        margin: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;


        .btn {
          width: 46%;
          height: 28px;
          background: #F2F2F2;
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;

          line-height: 28px;
          font-weight: 400;
          color: #000000;
          cursor: pointer;

        }

        .btnchecked {
          width: 46%;
          height: 28px;
          background: #E77817;
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 28px;
        }

      }

      .phheader {
        // margin: 10px auto;
        width: calc(100% - 40px);
        margin: 0 20px;
        box-sizing: border-box;
        height: 34px;
        background: rgba(0, 163, 228, 0.2);
        display: flex;
        margin-bottom: 5px;

        .hp1 {
          width: 48px;
          height: 34px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 34px;
        }

        .hp2 {
          flex: 1;
          height: 34px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 34px;
          text-align: left;
          padding-left: 40px;
        }

        .hp3 {
          width: 86px;
          height: 34px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 34px;
        }

      }

      .linebox {
        // width: 356px;
        width: 100%;
        // padding: 0 20px;
        box-sizing: border-box;
        margin: 0 auto
      }

      .line {
        // width: 356px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        border-bottom: solid 1px #F0F3F8;
        display: flex;

        .showrank {
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          .rank {
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: #E77817;
            color: #fff;
            font-weight: bold;
            font-size: 10px;
            border-radius: 4px;
          }

        }

        .showname {
          flex: 1;
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          height: 40px;
          line-height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;


        }

        .showtext {
          width: 66px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          text-align: right;
          margin-right: 20px;
        }

      }

      .line:last-child {
        border-bottom: none;
      }



    }



  }
}


.indexcontainer {
  background: #f0f3f8;
  width: 100%;
  min-width: 1200px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.iheader {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    margin-right: 30px;
  }

  .lefttitle {
    display: flex;
    justify-content: center;
    align-items: center;

    .ih-title {
      margin-left: 30px;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #676b78;
      line-height: 30px;
      cursor: pointer;
    }

    .titleselect {
      font-size: 24px;
      color: #333640;
    }

  }


}

.icontent {
  box-sizing: border-box;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 96%;
  margin-left: 2%;
  flex: 1;
  height: calc(100vh - 160px);


}

.hight25 {
  height: 25%;
}

.downrise {
  color: green;
}
</style>