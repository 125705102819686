<template>
	<div class="bluetitlebox">
		<div class="left">
		<div class="blue"></div>
		<div class="name">{{ name }}</div>
		</div>
		<div  v-if="moretext!==''" class="more" @click="tourl(url)">
			<span class="moretext">{{ moretext }}</span>
			<img class="moreicon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAASCAYAAAE26y4IAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAEgAAAACLTU82AAACTUlEQVQ4EZWVz2sTQRTH3+zWxuIPxIv4B0il0IpgDx6UHOzBUrSIPTTJoejBJF68aUGbl4oeevFkGgSL2KT4EyyCIIr05KmHokIh/jh4KwhqK2qq7fidTWb7luzaZGGYN9/3me+8fTsklEzzDMkncT5/0lsns/xaJqhJNJnhb3afjR1PcJ0jiXPcH4iHM9xjaRl7WirDH2xSxp4Gb22TfuwHyPgxgmVB+rE7lOXtBw/Fqwd64x1v5udeWihKVxYwM1xnMZ3Yt4dcZl63OanX3rueWf3SdcqElSV6YGEzS907AQ7XoY/Gqh07p6Yurlg4VIe4YAE5R+kOKfqEpEbNgfKi9FpJaR4A8NTR1D1d5Hf2JNyJBt01ybfzc5Wew/EbWtPn7t54O9avovRAWw2E8sxHWihP8lGzto/VA3VDnACwY1VR7WrV6VAd4kcM/4IK54CuTHfeL9EaaZotF3nQglG6B2tHDUrYbDImYbpCGS+QP4bxk5Q6Uy7k7psNUU+rfL2rWqXS45e10nkYG+25ptjwzOTo1/CDmucbPlsqc7VTq7Un6Nl+mP9QWo2UirnH4QcRbcY3HLBhhCqz+Rwu1xg0pRQ9q/7dmnh469L3DUZG4XzgHkkcnrpUYIZzEvovHNTf7v5+FGTkKpwPfYOhs7x7S4zuKU19sFhH/WP4+NeknYz/x7dJEL/Yp9H7O9C2YV50Heq8e5MrkpFxM7waucC7/lTJ/Ekcx9Do9XipkMubFkkzG7fKt8H8NjbvdZTbNV24slgzYuvXMLfK/wMymkj/G4GTmwAAAABJRU5ErkJggg==" alt="">
		</div>

	</div>
</template>
<script >
export default {
	props:{
		name:{
			type:String,
			default:''
		},

		moretext:{
			type:String,
			default:'更多'
		},

		url:{
			type:String,
			default:''
		}
	},

  data() {
		return {

		}
	},

	methods:{
		tourl(url){
			if(url!==''){
				this.$router.push(url)
			}
		}
	}
}
</script>
<style lang="scss">
	.bluetitlebox{
		display: flex;
		justify-content: space-between;
		padding: 20px 20px 15px 20px;
		box-shadow: border-box;
		.left{
			display: flex;
			.blue{
				width: 4px;
				height: 20px;
				background: #00A3E4;
			}
			.name{
				margin-left:10px;
				height: 20px;
				font-size: 18px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				color: #333640;
				line-height: 20px;
			}
		}
		.more{
			cursor: pointer;
			display: flex;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #878787;
				justify-content: center;
				align-items: center;
				width:60px;
			.moretext{
				align-items: center;
				font-size:13px;
			}
			.moreicon{
				margin-left:4px;
				height:10px;
				width:10px;
			}
		
		}

	}
</style>
