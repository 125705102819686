<template>
  <div class="continer">


    <el-calendar class="ccalender" v-model="currentdate">
      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
      <template slot="dateCell" slot-scope="{date, data}">

        <p :class="data.isSelected ? 'is-selected' : ''" @click="changeDay(data.day)">
          <!-- {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '' : ''}} -->
          {{ data.day.split('-').slice(1)[1] }}
          {{ data.isSelected ? '' : '' }}
          <div class="ccon">
            <div v-for="item in markList" :key="item.showDate">
              <!-- <div>{{ data.day }}</div> -->
              <div class="cconbtn" v-if="item.showDate === data.day" @click="changeDay(data.day)">
                
                <div v-if="item.zzgg >= 1" class="orange"> {{item.zzgg}}</div>
                <div v-if="item.cj >= 1" class="blue"> {{item.cj}}</div>
                <div v-if="item.daipai >= 1" class="green"> {{item.daipai}}</div>
              </div>

            </div>

          </div>
        </p>
      </template>

    </el-calendar>
    <div class="miaoshu">
      <div class="orange"></div>
      <div> 公告</div>
      <div class="blue"></div>
      <div> 成交</div>
      <div class="green"></div>
      <div> 待拍</div>
    </div>


    <el-dialog :title="ymd" width="60%" :visible.sync="dialogTableVisible">
      <div class="ileft">
        <div class="onebox" v-if="listdata.daipai.length > 0" >
          <blue-title name="待拍" url="/data/notice" ></blue-title>
          <el-table :data="listdata.daipai" stripe class="tableBox" style="width: 100%">
            <el-table-column prop="zname" label="区县">
            </el-table-column>
            <el-table-column prop="locationnum" :show-overflow-tooltip='true' label="宗地编号" width="180">
            </el-table-column>
            <el-table-column prop="usecatename" label="用途">
            </el-table-column>
            <el-table-column prop="acreage" :show-overflow-tooltip='true' label="面积(m²)">
            </el-table-column>

            <el-table-column prop="bond" label="保证金(万元)">
            </el-table-column> 
            <el-table-column prop="totalsp" :show-overflow-tooltip='true' label="起始总价(万元)">
            </el-table-column> 
           
          </el-table>

        </div>

        <div class="onebox"  v-if="listdata.zzgg.length > 0">
          <blue-title name="公告" url="/data/notice" ></blue-title>
          <el-table :data="listdata.zzgg" stripe class="tableBox" style="width: 100%">
            <el-table-column prop="zname" label="区县">
            </el-table-column>
            <el-table-column prop="locationnum" :show-overflow-tooltip='true' label="宗地编号" width="180">
            </el-table-column>
            <el-table-column prop="usecatename" label="用途">
            </el-table-column>
            <el-table-column prop="acreage" :show-overflow-tooltip='true' label="面积(m²)">

            </el-table-column>

            <el-table-column prop="bond" label="保证金(万元)">
            </el-table-column> 
            <el-table-column prop="totalsp" :show-overflow-tooltip='true' label="起始总价(万元)">
            </el-table-column> 
            <!-- <el-table-column prop="biddingst" :show-overflow-tooltip='true' label="限时竞价时间">
            </el-table-column> -->
          </el-table>

        </div>
        <div class="twobox" v-if="listdata.cj.length > 0" >
          <blue-title name="成交" url="/data/deal"></blue-title>
          <el-table :data="listdata.cj" stripe class="tableBox" style="width: 100%">
            <!-- <el-table-column prop="tradedate" :show-overflow-tooltip='true' label="成交时间">
            </el-table-column> -->
            <el-table-column prop="zname" :show-overflow-tooltip='true' label="区县">
            </el-table-column>
            
            <el-table-column prop="locationnum" :show-overflow-tooltip='true' label="宗地编号">
            </el-table-column>
            <el-table-column prop="usecatename" :show-overflow-tooltip='true' label="用途">
            </el-table-column>
           
            <el-table-column prop="acreage" :show-overflow-tooltip='true' label="面积(m²)">
            </el-table-column> 
            <el-table-column prop="tradeprice" :show-overflow-tooltip='true' label="成交总价(万元)">
            </el-table-column>
            <el-table-column prop="company" :show-overflow-tooltip='true' label="竞得单位">
            </el-table-column>
          </el-table>
        </div>

      </div>
    </el-dialog>




  </div>
</template>

<script>

import { dataindexlist,getdialogList } from "@/api/index.js";

import BlueTitle from '@/components/BlueTitle.vue';
// import { getDateList, getMarkList } from "@/api/index";
export default {
  name: "FrontendPcBigdataCalendar",
  components:{
    BlueTitle
  },
  data() {
    return {
      listdata:{
        zzgg:[],
        cj:[],
        daipai:[]
      },
      ymd:"土拍日程",
      dialogTableVisible:false,
      currentdate: null,
      markList: [],
      date: {},
      data: {},
      listByDate: {
        bmjzr: [],
        jpr: [],
      },
    };
  },

 


  computed: {
    shi: {
      get() {
        return this.$store.state.shi
      },
      set(v) {
        this.$store.dispatch('changeShi', v);
      }
    }
  },

  
  watch: {
    currentdate(nv) {
      const ymd = this.getDateYMDString(nv);
      this.ymd = "土拍日程（"+ymd+")"
      // console.log('===currentdate====',ymd)
      const ym = ymd.slice(0, 7);
      this.marklist(ym);
    },
    shi: {
      handler(v) {

        const ymd = this.getDateYMDString(this.currentdate);
        this.ymd = "土拍日程（"+ymd+")"
        const ym = ymd.slice(0, 7);
        this.marklist(ym);
      }
    }
  },

  mounted() {
    const ymd = this.getDateYMDString();
    // const ym = this.getDateYMString();

    //
    // this.datelist(ymd);
    this.currentdate = ymd;
    const ym = ymd.slice(0, 7);
    this.marklist(ym);
  },

  methods: {
    changeDay(item) {
      
      this.dialogTableVisible = true

      this.showdialogList(item)

    },

    getDateYMDString(string = null) {
      let d;
      if (string) {
        d = new Date(string);
      } else {
        d = new Date();
      }

      const y = d.getFullYear();
      const getm = d.getMonth();
      let day = d.getDate();

      let m = "";
      if (getm < 9) {
        m = "0" + (getm + 1);
      } else {
        m = getm + 1;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return y + "-" + m + "-" + day;
    },

    toUrl() {
      this.$router.push("/index");
    },
    showList(riqi) {
      this.marklist(riqi);
      // this.datelist(riqi);
    },

    marklist(riqi) {
      var that = this;

      const markParams = {
        dcity_id: this.shi,
        ym: riqi.slice(0, 7),
      };
      dataindexlist(markParams).then((res) => {
        that.markList = res.data.calendar;
      });
    },

    showdialogList(ymd){
      const params = {
        dcity_id: this.shi,
        ymd: ymd
      };

      getdialogList(params).then((res) => {
        this.listdata = res.data;
      });

    }

    // datelist(riqi) {
    //   var that = this;
    //   const params = {
    //     showdate: riqi,
    //   };

    //   getDateList(params).then((res) => {
    //     that.listByDate = res.data;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>


/deep/ .el-calendar-table td.is-selected{
  background:rgb(127, 213, 234);
}

/deep/.tableBox {
  th {
    padding: 0 !important;
    height: 9px;
    line-height: 9px;
  }
  td {
    padding: 0 !important;
    height: 22px;
    line-height: 22px;
  }
}


.ileft {
    box-sizing: border-box;
    width: 100%;
    height: 94%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .onebox{
      width: 100%;
      
    box-sizing:border-box;
      min-height:195px;
      background: #FFFFFF;
      border-radius: 10px;
    }
    .twobox{
      margin-top:10px;
      width: 100%;
      min-height:195px;
      background: #FFFFFF;
      border-radius: 10px;
    }
    .threebox{
      width: 100%;
      min-height:200px;
      background: #FFFFFF;
      border-radius: 10px;
    }
  }


::v-deep .el-calendar__header {
  padding-top: 0;
}

::v-deep .el-calendar__title {
  font-size: 16px;
}

::v-deep .itemcell {
  font-size: 14px;
}

::v-deep .el-calendar-table .el-calendar-day {
  height: 36px;
}

::v-deep .el-calendar-table thead th {
  background: #00A3E4;
  color: #fff;
  border-right: solid 1px #fff;
}

.itemcell {
  height: 35px;
}

.ccon {
  height: 20px;
}

.miaoshu {
  // background: red;
  position: absolute;
  width:100%;
  text-align: center;
  bottom: 5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  .orange,
  .green,
  .blue {
    margin-left: 12px;
    margin-right: 12px;
   

  }
}

.continer {
  position: relative;
  top:-8px;
  min-width: 350px;
  margin-left: 15px;
  background: #fff;
  .ccalender {
    position: relative;
  }
}

// .calendarleft {

 
// }

.cconbtn {
  display: flex;
  justify-content: space-around;
  position: relative;
  top:-40px;
}

.orange {
  height: 14px;
  width: 14px;
  font-size:10px;
  border-radius: 50%;
  background: #e88f09;
  color:#fff;
}

.blue {
  height: 13px;
  width: 13px;
  font-size:10px;
  border-radius: 50%;
  background: #00a3e4;
  color:#fff;
}

.green {
  height: 13px;
  width: 13px;
  font-size:10px;
  border-radius: 50%;
  background: rgb(219, 12, 12);
  color:#fff;
}




.top {
  display: flex;
  background: #FFFFFF;

  .subject {
    width: 170px;
    height: 44px;

    border-radius: 2px;
    margin: 30px 20px;
    margin-top: 20px;
  }

}

</style>